<style scoped>
    .elevation-2 {
        border-radius: 0;
    }
    .expand-area {
        padding: 20px !important;
        background: #eee;
    }
</style>

<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items-per-page="100"
                        :items="categories"
                        item-key="Id"
                        :loading="loading"
                        class="elevation-1"
                        :hide-default-footer="true"
                        show-expand
                        :single-expand="true"
                        :expanded.sync="expanded"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        <router-link :to="'/categories/create'" class="btn btn-success font-weight-bold btn-sm">
                                            <v-icon left>mdi-plus</v-icon> Thêm
                                        </router-link>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- <v-text-field
                                        append-icon="mdi-magnify"
                                        single-line
                                        hide-details
                                        v-model="query"
                                        label="Search"
                                        class="mx-4"
                                        ></v-text-field> -->
                                    </div>
                                </div>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <td v-if="item.list_child_cates && item.list_child_cates.length" :colspan="headers.length" :col-offset="3" class="expand-area">
                                    <v-data-table
                                    :headers="headers_child"
                                    :items-per-page="100"
                                    :items="item.list_child_cates"
                                    :loading="loading"
                                    class="elevation-2"
                                    :hide-default-footer="true"
                                    >
                                        <template v-slot:top>
                                            <v-toolbar flat>
                                                <v-toolbar-title>Nhóm sản phẩm con của: {{ item.Name }}</v-toolbar-title>
                                                <v-spacer></v-spacer>
                                            </v-toolbar>
                                        </template>
                                        <template v-slot:item.Status="{ item }">
                                            {{ item.Status == 1 ? "Hiện" : "Ẩn" }}
                                        </template>
                                        <template v-slot:item.Thumbnail="{ item }">
                                            <img :src="item.Thumbnail" style="width: 50px; height: 50px" />
                                        </template>
                                        <template v-slot:item.actions="{ item }">
                                            <router-link :to="`/categories/update/${item.Id}`">
                                                <v-icon small class="mr-2">
                                                    mdi-pencil
                                                </v-icon>
                                            </router-link>
                                            <v-icon small @click="confirm_delete(item)" >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                    </v-data-table>
                                </td>
                            </template>
                            <template v-slot:item.Thumbnail="{ item }">
                                <img :src="item.Thumbnail" style="width: 50px; height: 50px" />
                            </template>
                            <template v-slot:item.Status="{ item }">
                                {{ item.Status == 1 ? "Hiện" : "Ẩn" }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <router-link :to="`/categories/update/${item.Id}`">
                                    <v-icon small class="mr-2">
                                        mdi-pencil
                                    </v-icon>
                                </router-link>
                                <v-icon small @click="confirm_delete(item)" >
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Nhóm sản phẩm'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: '',
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Hình ảnh", value: 'Thumbnail', sortable: false },
                { text: "Tên", value: 'Name', sortable: false },
                { text: "Mô tả", value: 'Description', sortable: false },
                { text: "Trạng thái", value: 'Status', sortable: false },
                { text: "Ordinal", value: 'Ordinal', sortable: false },
                { text: "Actions", value: 'actions', sortable: false },
                { text: '', value: 'data-table-expand' },
            ],
            headers_child: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Hình ảnh", value: 'Thumbnail', sortable: false },
                { text: "Tên", value: 'Name', sortable: false },
                { text: "Mô tả", value: 'Description', sortable: false },
                { text: "Trạng thái", value: 'Status', sortable: false },
                { text: "Ordinal", value: 'Ordinal', sortable: false },
                { text: "Actions", value: 'actions', sortable: false },
            ],
            expanded: []
        };
    },
    watch: {
        options: {
            handler () {
                this.get_users();
            },
            deep: true,
        },
    },
    computed: {
        categories() {
            function find_all_child_cate(cates, cate_id) {
                var child_cates = [];
                for(var j in cates) {
                    if(cates[j].Parent_cate_id == cate_id) {
                        child_cates.push(cates[j]);
                    }
                }
                return child_cates;
            }

            var all_categories = this.$store.getters['categories/get_categories'];
            var parent_category = [];
            for(var i in all_categories) {
                if(!all_categories[i].Parent_cate) {
                    parent_category.push(all_categories[i]);
                    var child_cates = find_all_child_cate(all_categories, all_categories[i].Id);
                    all_categories[i].list_child_cates = child_cates;
                }
            }

            return parent_category;
        },
    },
    components: {
        
    },
    methods: {
        get_categories() {
            var self    = this;
            self.loading = true;

            this.$store.dispatch('categories/get_all').then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        // search_users() {
        //     const page  = 1;
        //     const limit = this.$route.query.limit || 10;

        //     var self    = this;
        //     self.loading = true;
        //     var payload = {
        //         page,
        //         limit,
        //         toast: this.$root.$bvToast,
        //         i18n: this.$i18n,
        //     };
        //     if(this.query) {
        //         payload.query = this.query;
        //     }
        //     this.$router.push({
        //         name: "users-index",
        //         query: { page, limit }
        //     }).catch(() => { });

        //     this.$store.dispatch('users/get_users', payload).then(() => {
        //         self.loading = false;
        //     }).catch(() => {
        //         self.loading = false;
        //     });
        // },
        edit_item(item) {
            console.log("edit item:", item);
            this.$router.push(`categories/update/${item.id}`).catch(() => { });
        },
        confirm_delete(item) {
            console.log("delete item:", item);
            var self = this;
            Swal.fire({
                title: `Xoá nhóm sản phẩm ${item.Name}?`,
                // text: `Xoá nhóm sản phẩm ${item.Name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item(item.Id);
                }
            })
        },
        delete_item(id) {
            this.$store.dispatch('categories/delete_category', { id, toast: this.$root.$bvToast }).then(() => {
                this.loading = false;
                this.get_categories();
            }).catch(() => {
                this.loading = false;
            });
        },
        update_page(pagination) {
            const page  = pagination.page;
            const limit = pagination.itemsPerPage;

            this.$router.push({
                name: "categories-index",
                query: { page, limit }
            }).catch(() => { });
            this.get_categories();
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Nhóm sản phẩm", route: "index" }, { title: "Tất cả" } ]);
        this.get_categories();
    }
};
</script>
